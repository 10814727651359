@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Bold Italic'), local('./fonts/GothamPro-BoldItalic'),
    url('assets/fonts/GothamPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Italic'), local('./fonts/GothamPro-Italic'),
    url('assets/fonts/GothamPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Medium'), local('./fonts/GothamPro-Medium'),
    url('assets/fonts/GothamPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Black Italic'), local('./fonts/GothamPro-BlackItalic'),
    url('assets/fonts/GothamPro-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Bold'), local('./fonts/GothamPro-Bold'),
    url('assets/fonts/GothamPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro Narrow';
    src: local('./fonts/Gotham Pro Narrow Bold'), local('./fonts/GothamProNarrow-Bold'),
    url('assets/fonts/GothamProNarrow-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro Narrow';
    src: local('./fonts/Gotham Pro Narrow Medium'), local('./fonts/GothamProNarrow-Medium'),
    url('assets/fonts/GothamProNarrow-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Light Italic'), local('./fonts/GothamPro-LightItalic'),
    url('assets/fonts/GothamPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Light'), local('./fonts/GothamPro-Light'),
    url('assets/fonts/GothamPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Black'), local('./fonts/GothamPro-Black'),
    url('assets/fonts/GothamPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro'), local('./fonts/GothamPro'),
    url('assets/fonts/GothamPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('./fonts/Gotham Pro Medium Italic'), local('./fonts/GothamPro-MediumItalic'),
    url('assets/fonts/GothamPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

html, body, #root, .wrap {
    min-height: 100%;
    height: 100%;
    font-family: 'Gotham Pro', sans-serif;
    line-height: 1;
    background-color: #eff0f5;
    transition: all 0.2s ease-in-out;
}

.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}

.mobile-header {
    display: none;

    width: 100%;
    height: 100%;

    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    position: absolute;
    z-index: 100;
}

.mobile-header > div:first-of-type {
    margin-top: 100px;
    border-top: 1px solid #e9e9e9;
    height: 100%;
    width: 100%;
}

.header-holder {
    background-color: transparent;
}

.header-holder.black-on-white {
    background-color: white;
}

.header-holder.white-on-black {
    color: white !important;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    width: 100%;
    max-width: 980px;
    padding: 0 32px;
    height: 100px;
    margin: 0 auto;
    font-weight: 500;
    color: white;

    z-index: 1000;
    transition: all 0.2s ease-in-out;
}

.header.black-on-white, .logo-title.black-on-white, .header-items.black-on-white > .header-item, .lang-dropdown.black-on-white .lang__single-value {
    color: #272424 !important;
}

.lang-dropdown.black-on-white {
    border-color: #272424 !important;
}

.header-items {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-item {
    color: white !important;
    margin-left: 24px;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
}

.header-item:hover {
    text-decoration: none;
    color: #cacaca;
}

.header-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

a.header-logo {
    text-decoration: none;
}

.logo-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    width: 42px;
    height: 42px;
    color: white;
    background-color: #47ab43;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.logo-title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 10px;
    color: white;
    transition: all 0.2s ease-in-out;
}

.lang-dropdown {
    border: 1px solid white !important;
    border-radius: 25px !important;
    cursor: pointer;
}

.lang__control {
    /*border-radius: 25px !important;*/
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    flex-wrap: nowrap !important;
    cursor: pointer !important;
}

.lang__control--menu-is-open {
    border: 1px solid white !important;
}

.lang__value-container {
    width: 100%;
    min-width: 50px;
    color: white !important;
}

.lang__placeholder, .lang__single-value {
    color: white !important;
}

.lang__indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.lang__menu {
    min-width: 175px;
    max-width: 320px;
    right: 0;
    padding: 12px 0;
}

.lang__option {
    padding: 8px 20px 8px 43px !important;
    transition: all 0.1s ease-in-out;
    font-size: 13px !important;
    white-space: nowrap;
    cursor: pointer !important;
    background-color: transparent !important;
    color: #272424 !important;
    position: relative;
    font-weight: 400;
}

.lang__option--is-focused {
    background-color: rgb(246, 245, 245) !important;
}

.lang__option--is-selected::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    margin-right: 10px;
    color: #47ab43;
    position: absolute;
    left: 20px;
    font-size: 16px;
}

.lang__option--is-selected span {
    color: white !important;
}

.lang__control--is-focused {
    border-color: transparent !important;
    box-shadow: none !important;
}

.trip-bg {
    min-height: 400px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.loader {
    display: flex;
    justify-content: center;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.trips-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    padding: 80px 8px 60px;
    margin-top: -100px;

    background-color: rgba(34, 35, 34, 0.6);
    background-blend-mode: overlay;
}

.trips-title {
    color: white;
    font-weight: 500;
    font-size: 34px;
    margin-bottom: 24px;
}

.trips-title-routes {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    text-align: left;

    max-width: 940px;
    width: 100%;

    color: #424543;
    font-weight: 500;
    font-size: 28px;
    margin: 18px 0 4px;
    padding: 0 8px;

    transition: all 0.2s ease-in-out;
}

.trips-title-routes > span:last-of-type {
    font-weight: 400;
    font-size: 16px;
    margin-left: 5px;
}

.trips-search-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 0 1px 0;
    max-width: 1044px;

    transition: all 0.2s linear;
    /*-webkit-animation: container-expand-animation-x 0.2s ease-in-out;*/
    /*-moz-animation: container-expand-animation-x 0.2s ease-in-out;*/
    /*-o-animation: container-expand-animation-x 0.2s ease-in-out;*/
    /*animation: container-expand-animation-x 0.2s ease-in-out;*/
}

.trip-search-directions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.28) 1px 8px 15px;
}

.trip-search-directions > div .custom-dropdown__control, .trip-search-date .date-input input {
    border: none;
    border-right: 1px solid rgb(233, 233, 233);
}

.trip-search-directions > div:first-child .custom-dropdown__control {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.trip-search-date {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.28) 1px 8px 15px;
}

.trip-search-date > .form-custom-input {
    width: 100%;
}

.trip-search-date > .form-custom-input > .date-input {
    background-color: white;
}

.form-custom-input {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 140px;
}

.form-custom-input > div {
    max-width: 195px;
}

.form-custom-input > div > div, .form-custom-input > div > input {
    min-height: 60px;
}

.form-custom-input > div > div, .form-custom-input > div > input {
    border-radius: 0;
}

.date-input > input, .trips-search-container > .form-custom-input input:last-child {
    position: relative;
}

.date-input > input[readonly], .input-type-number > input {
    background-color: white !important;
}

.trips-search-container .form-custom-input input:focus, .input-type-number > div:first-child > button:focus {
    box-shadow: none !important;
}

.relative {
    position: relative;
}

.btn-swap {
    position: absolute;
    top: calc(50% - 8px);
    left: 85%;
    z-index: 1;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    transition: transform 150ms ease 0ms;
    transform: scaleX(-1);
    color: #51bb4c;
    min-width: auto !important;
    background-color: white;
    max-width: 20px !important;
    max-height: 20px !important;
}

.btn-swap.normal {
    transform: scaleX(-1);
}

.btn-swap.invert {
    transform: rotate(180deg);
}

.trip-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.icon {
    pointer-events: none;
    color: #47AB43;
    position: absolute;
    right: 10px;
    font-size: 20px;
}

.date-input > input[readonly], .input-type-number > input {
    background-color: white !important;
}

.input-type-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    padding: 0 12px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.input-type-number > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.input-type-number > div:first-child > input {
    max-width: 130px !important;
    border: none;
    color: #495057 !important;
    background-color: transparent;
}

.input-type-number .counter {
    max-width: 30px !important;
    text-align: center;
    padding: 0;
}

.input-type-number > div:first-child > button {
    height: 60px !important;
    border: none;
    border-radius: 0;
    font-size: 18px;
    color: #d21f1f !important;
    font-weight: 100;
    line-height: 1.5;
    padding: 2px;
}

.input-type-number > div:first-child > button:focus {
    box-shadow: none;
}

.input-type-number > div:first-child > button:last-child {
    color: #169ad7 !important;

    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

/*datepicker customization*/
.rdtDays {
    margin: 10px;
}

.rdtDays thead > tr:last-child > .dow:nth-last-of-type(-n+2) {
    color: red;
}

.rdtDays .dow {
    width: 32px;
    height: 32px;
    padding: 0.5em 0px;
    font-size: 14px;
    color: rgb(83, 83, 83);
    text-align: center;
    font-weight: 400;
    text-transform: capitalize;
}

.rdtPicker {
    width: 100% !important;
    padding: 10px !important;
    top: calc(100% + 8px);
    box-shadow: rgba(0, 0, 0, 0.275) 6px 6px 30px !important;
    border-radius: 6px !important;
}

.rdtSwitch {
    color: rgb(39, 36, 36);
    text-transform: capitalize;
    font-size: 16px;
    line-height: 1.5;
    padding-bottom: 16px;
    font-weight: 400;
    transition: all 0.1s ease-in-out;
}

.rdtPrev, .rdtNext {
    font-size: 36px !important;
    font-weight: 100;
    padding-bottom: 16px;
    transition: all 0.1s ease-in-out;
}

.rdtPrev:hover, .rdtSwitch:hover, .rdtNext:hover {
    background-color: transparent !important;
    color: #47AB43;
}

.rdtDay {
    height: 32px !important;
    padding: 0px;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.rdtYear, .rdtMonth, .rdtDay {
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
}

.rdtYear:hover, .rdtMonth:hover, .rdtDay:hover {
    border-radius: 5px;
    background-color: #47ab43c9 !important;
    color: white !important;
}

.rdtActive {
    background-color: #47AB43 !important;
    color: white !important;
}

.rdtNew {
    display: none;
}

.rdtOld {
    color: rgb(177, 177, 179) !important;
}

.rdtDisabled {
    color: rgb(220, 224, 224) !important;
}

.rdtDays tbody > tr > .rdtDay:nth-last-of-type(-n+2) {
    color: #f62c3f;
}

.rdtToday::before {
    border-bottom: 7px solid #47AB43 !important;
}

.rdtDisabled:hover {
    color: rgb(220, 224, 224) !important;
    background-color: transparent !important;
}

.tickets-search-btn {
    margin: 0;
}

.tickets-search-btn {
    border-radius: 5px !important;
    min-height: 60px;
    background-color: #47AB43 !important;
    max-width: 195px;
    width: 100% !important;
    font-size: 14px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.28) 0px 5px 10px;
}

.tickets-search-btn:hover {
    background-color: #51bb4c !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125), 0 0 8px rgba(71, 171, 67, 0.5) !important;
    color: white;
}

.tickets-search-btn:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125), 0 0 8px rgba(71, 171, 67, 0.5) !important;
}

.tickets-search-btn:disabled {
    opacity: 1;
    background-color: #424543 !important;
}

.custom-dropdown__input input, .dropdown-list__input input {
    width: 100% !important;
}

.custom-dropdown__menu {
    z-index: 10 !important;
    border: 1px solid rgb(218, 212, 212) !important;
    border-radius: 4px !important;
    min-width: 374px;
    max-height: 430px;
    box-shadow: rgba(0, 0, 0, 0.275) 6px 6px 30px;
}

.custom-dropdown__single-value .dropdown-name {
    font-size: 16px;
    font-weight: 400;
}

.custom-dropdown__single-value .dropdown-address {
    display: none;
}

.custom-dropdown__option, .dropdown-list__option {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    min-height: 36px;
    height: auto;
    padding: 8px 20px 8px 43px !important;
    transition: all 0.1s ease-in-out;
    font-size: 14px !important;
    /*white-space: nowrap;*/
    cursor: pointer !important;
    background-color: transparent !important;
    color: #272424 !important;
    position: relative;
    font-weight: 400;
}

.custom-dropdown__option--is-focused, .dropdown-list__option--is-focused {
    /*height: 100%;*/
    background-color: rgb(246, 245, 245) !important;
}

.custom-dropdown__option--is-selected::before, .dropdown-list__option--is-selected::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    margin-right: 10px;
    color: #47ab43;
    position: absolute;
    left: 20px;
    font-size: 16px;
}

.custom-dropdown__option--is-selected span, .dropdown-list__option--is-selected span {
    /*color: red !important;*/
}

.custom-dropdown__control--is-focused {
    border-color: transparent !important;
    box-shadow: none !important;
}

.custom-dropdown__value-container {
    margin-top: 5px;
    padding: 2px 10px !important;
}

.input-type-number input:focus, .date-input input:focus {
    outline: none;
    box-shadow: none;
}

.trip-search-btn {
    margin-left: 10px;
}

.dropdown-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: rgb(39, 36, 36);
}

.dropdown-address {
    font-size: 12px;
    line-height: 1;
    color: #535353;
}

.input-placeholder {
    /*display: block !important;*/
    position: absolute;

    color: rgb(180, 171, 171) !important;
    font-size: 10px;
    transform: translateY(-16px);

    left: 12px;
    z-index: 1;
    max-width: calc(100% - 40px);
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    pointer-events: none;
    transition: all 0.15s ease-in-out 0s;
}

.trip-date > input {
    padding-top: 13px;
}

.trip-passengers > div:first-child > button {
    height: 20px !important;
}

.trip-passengers > div:first-child > input {
    margin-top: 5px;
}

.trips-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/

    width: 100%;
    max-width: 940px;
    padding: 0px 8px;
    margin: 0px auto;
}

.no-trips {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    width: auto;
    max-width: 930px;

    height: auto;
    min-height: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 25px;
    margin: 35px 8px;
    -webkit-align-self: center;
    align-self: center;

    box-shadow: 0 0 15px -10px #969696;
}

.no-trips > div:first-child {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    font-size: 64px;
    color: #47AB43;
}

.no-trips > div:last-child > div:first-child {
    font-size: 38px;
    line-height: 1.42857;
    color: #424543;
}

.no-trips > div:last-child > div:last-child {
    font-size: 18px;
    line-height: 1.42857;
    color: #424543;
}

.trip-item {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: #ddd 1px 2px 5px;
    /*box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;*/
    height: auto;
    /*min-width: 980px;*/
    /*height: 146px;*/
}

/*.trip-item > div {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: flex-start;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

.trip-item-short {
    display: grid;
    grid-template-columns: 70% 30%;

    width: 100%;
    height: 100%;
}

.trip-item-carrier {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 5px 0;
    color: #6c6c6c;
    max-height: 28px;

    font-size: 12px;
    text-align: left;
}

.trip-item-carrier > span:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #47AB43;
    border: 1px solid #bebebe;
    border-top-left-radius: 25%;
    border-bottom-right-radius: 25%;
    padding: 3px;
    height: 28px;
    width: 28px;
    margin-right: 10px;
}

.trip-item-short-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 10px;
}

.trip-item-short-left > div {
    margin-top: 10px;
}

.trip-item-short-left > div:first-of-type {
    margin-top: 0 !important;
}

.trips-short-info {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    text-align: left;
    align-items: flex-start;
}

.trips-short-info > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.trips-short-info > div > div {
    margin-top: 10px;
}

.trips-short-info > div > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    margin-top: 5px;
    width: 100%;
}

.trips-short-info > div > div:first-of-type > div:first-of-type > span:first-of-type {
    color: #272424;
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
    margin-right: 5px;

    transition: all 0.2s ease-in-out;
}

.trips-short-info > div > div:first-of-type > div:first-of-type > span:last-of-type {
    font-size: 14px;
    color: #9c9c9c;
}


.trips-short-info > div:first-of-type > div:first-of-type > div > i {
    color: #9c9c9c;
    font-size: 24px;
}

.trips-short-info > div > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding-right: 10px;

    font-size: 14px;
    line-height: 1.12857;
    color: #424543;
    font-weight: 500;
}

.trips-short-info > div > div:last-of-type > span:last-of-type {
    font-size: 12px;
    font-weight: 400;
}


.trip-details-btn-close, .trip-details-btn-open {
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin-top: 15px !important;*/
    max-width: 140px;
    padding: 5px 8px !important;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1;

    opacity: 0.8;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background-color: white;

    border: 1px solid #9c9c9c;
    color: #9c9c9c;
}

.trip-details-btn-open {
    border: 1px solid #47AB43;
    color: #47AB43;
}

.trip-details-btn-open::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f0d7";
    font-weight: 900;
    margin-left: 10px;
    color: #47ab43;
    font-size: 16px;
}

.trip-details-btn-close::after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    margin-left: 10px;
    font-size: 16px;
    content: "\f0d8";
    color: #9c9c9c;
}

.trip-time-in-road {
    color: #9c9c9c;
    font-weight: 500;
}

.trip-time-in-road::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f017";
    font-weight: 500;
    margin-right: 5px;
    font-size: 18px;
}

.trip-item-short-right, .profile-trip-item-short-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    padding: 10px 18px;
    background-color: #fafafc;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    border-left: 3px dotted #d7d7d7
}

.trip-item-cutout {
    height: 20px;
    width: 30px;
    background-color: #eff0f5;
    border-radius: 50%;
    position: absolute;
    left: -16px;
    z-index: 5;
}

.trip-item-cutout:first-of-type {
    top: -10px;
}

.trip-item-cutout:last-of-type {
    bottom: -10px;
}

.trip-buy-holder {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.trip-buy-btn {
    background-color: #47AB43;
    color: white;

    font-size: 18px;
    font-weight: 500;

    width: 100%;
    max-width: 195px;
    border-radius: 25px !important;
    min-width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;

    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125), 0 0 8px rgba(71, 171, 67, 0.5) !important;
    transition: all 0.2s ease-in-out;
}

.trip-buy-btn:hover {
    color: white;
}

.trip-buy-btn:hover {
    background-color: #51bb4c !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125), 0 0 8px rgba(71, 171, 67, 0.5) !important;
    color: white;
}

.trip-buy-btn:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125), 0 0 8px rgba(71, 171, 67, 0.5) !important;
}

.tickets-sort-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.sort-btn {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    height: 38px !important;
    padding-right: 14px;
    padding-left: 14px;
    margin: 0px 5px 10px !important;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    color: #2f3640 !important;
    width: auto !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px !important;
    transition: all 0.2s ease-in-out;
}

.sort-btn > i {
    color: #47AB43;
    margin-left: 5px
}

.sort-btn.active, .sort-btn:hover {
    border-color: #47ab43;
    box-shadow: rgba(71, 171, 67, 0.2) 0px 2px 4px !important;
}

.trip-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.trip-details-btns, .profile-trip-details-btns {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    /*justify-content: flex-start;*/
    width: 100%;
}

.trip-item-detailed, .profile-trip-item-detailed {
    /*display: none;*/

    display: grid;
    grid-template-columns: 70% 30%;
    width: 100%;
    height: auto;
    transform-origin: top;

    transition: all 0.3s ease-in-out;
}


.trip-item-detailed-left {
    /*border-top: 1px solid #6c6c6c;*/
    padding: 18px 8px 18px 15px;

}

.trip-item-detailed-left > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-top: 1px solid #d7d7d7;
    padding-top: 18px;
}


.trip-item-detailed-left > div:first-of-type > div {
    text-align: left;
    line-height: 1.29;
    font-size: 13px;
    width: 100%;
}

.trip-item-detailed-left > div:first-of-type > div > p {
    margin-bottom: 3px;
}

.trip-item-detailed-left > div:first-of-type > div:first-of-type {
    margin-bottom: 20px;
}

.trip-item-detailed-right {
    position: relative;
    padding: 18px 8px;
    background-color: #fafafc;

    border-bottom-right-radius: 10px;
    border-left: 3px dotted #d7d7d7
}

.trip-item-detailed-right > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-top: 1px solid #d7d7d7;
    padding-top: 18px;
    /*padding-left: 10px*/
}

.trip-item-detailed-right > div:first-of-type > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 10px;
    text-align: left;

    line-height: 1.29;
    font-size: 13px;
}

.trip-item-detailed-right > div:first-of-type > div > span:first-of-type {
    font-weight: 500;
}

.bold {
    font-weight: 500;
}

.ticket-route-border {
    display: flex;
    position: relative;
}

.ticket-route-border p {
    margin: 0;
}

.ticket-route-border > div {
    display: flex;
    flex-direction: column;
    min-width: 50px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    /*margin-right: 10px;*/

}

.ticket-route-border > div:first-child {
    margin-right: 20px;
    width: 50px;
}

.ticket-route-border > div:first-child > p:first-of-type {
    font-size: 18px;
}

.ticket-route-border > div:first-child > p:last-of-type {
    color: #9c9c9c;
}

.ticket-route-border > div:last-child > span {
    border-left: 0.125rem dotted #d8d8d8;
    padding-left: 20px;
    padding-bottom: 15px;
    margin-bottom: 8px;
    font-size: 13px;
}

.ticket-route-border > div:last-child > p {
    margin-bottom: 5px !important;
}

.ticket-route-border.alt-route-border > div:first-child {
    align-items: flex-end;
    margin-left: 5px;
    margin-right: 15px;
}

.ticket-route-border.alt-route-border > div:first-child > p {
    font-size: 14px;
}

.ticket-route-border.alt-route-border.checkout p {
    font-size: 12px;
}

.ticket-route-border.alt-route-border.checkout span {
    font-size: 10px;
}

.font-12px {
    font-size: 12px;
}

.ticket-route-dot {
    position: relative;
    margin-left: 20px !important;
}

.ticket-route-dot::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f2f8";
    position: absolute;
    left: -25px;
    font-size: 14px;
    color: #47AB43;
    font-weight: 700;
}

.ticket-route-dot.alt-dot::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f192";
    font-weight: 500;
    font-size: 10px;
}

.ticket-route-border-collapse {
    display: flex;
    flex-direction: column;
}

.ticket-route-border-collapse.visible {
    display: flex;
}

.ticket-route-border-collapse.hidden {
    display: none;
}

.ticket-currency {
    align-items: flex-end;
    white-space: pre-wrap;
}

.ticket-currency > span:last-child {
    font-size: 14px;
    line-height: 1.42857;
    color: #424543;
}

.no-border {
    border: none !important;
}

.is-mobile-only {
    display: none;
}

.is-desktop-only {
    display: initial;
}

.modal-captcha {
    top: unset !important;
}

.modal-captcha-backdrop > div > .modal-backdrop {
    opacity: 0.8 !important;
}

.modal-captcha > .modal-dialog {
    max-width: 350px;
    /*width: auto;*/
}

.modal-captcha > .modal-dialog > .modal-content {
    /*background-color: #e6fee1;*/
    border: 3px solid #b1cfaa;
    border-radius: 0;
    box-shadow: #3c3c3c 2px 4px 8px !important;

}

.captcha {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 5px;
}

.captcha > div {
    margin-bottom: 10px;
    width: 100%;
}

.captcha > div:first-of-type {
    margin-bottom: 5px !important;
    padding-bottom: 5px;
    border-bottom: 1px solid #d7d7d7;
}

.captcha input {
    width: 280px;
    height: 30px;
    background-color: white;
    box-shadow: none;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 20px;
    font-size: 28px;
    text-align: center;
    letter-spacing: 10px;
    margin-right: 5px;
}

.captcha input:focus {
    outline: none;
}

.captcha-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 10px 5px 0;
    border-top: 1px solid #d7d7d7;
}

.captcha-btns > button:first-of-type {
    width: 40px !important;
    background: none;
    font-size: 24px;
    color: #47AB43;
}

.captcha-input > button {
    width: 40px !important;
    background: none;
    font-size: 24px;
    color: #47AB43;
}

.captcha-btns > button {
    width: 120px;
    border: none;
    border-radius: 4px;
    height: 40px;
    background-color: #47AB43;
    color: white;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.captcha-btns > button:hover {
    background-color: #51bb4c;
}

.captcha-btns > button:first-of-type:hover {
    background: none !important;
    color: #51bb4c;
}

.captcha img {
    /*max-width: 233px;*/
    /*height: 50px !important;*/
    width: 100%;
    height: 50px;
}

.preorder-header-holder {
    background-color: #47AB43;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .15)
    /*width: ;*/
}

.preorder-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    width: 100%;
    max-width: 980px;
    padding: 20px 32px;
    margin: 0 auto;
}

.preorder-direction {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 500;
    word-break: break-all;
}

.preorder-direction > div {
    margin-right: 15px;
}

.preorder-direction > div > div {
    margin-bottom: 5px;
}

.preorder-price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    font-weight: 500;
}

.preorder-price > div {
    margin-bottom: 5px;
}

.thin-small {
    font-weight: 200;
    font-size: 12px;
}

.preorder-body {
    background-color: white;
    padding: 32px;
    width: 100%;
    max-width: 980px;
    margin: 20px auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
}

.preorder-body > div > div {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.42857;
    color: #424543;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preorder-body > div > div:first-of-type {
    font-size: 20px;
}

.preorder-body > div > div:last-child {
    font-size: 28px;
    margin-bottom: 12px;
}

.preorder-body > button {
    width: 195px;
    height: 50px;
    background-color: #47AB43;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;

    background-color: #47AB43;
    color: white;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.preorder-body > button:hover {
    background-color: #51bb4c;
}

.modal-confirmation > .modal-dialog {
    color: #636363;
    max-width: 400px !important;
    margin: 30px auto;
}

.modal-confirmation > .modal-dialog > .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
    text-align: center;
}

.modal-confirmation > .modal-dialog > .modal-content > .modal-header {
    border-bottom: none;
    position: relative;
    padding: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal-confirmation > .modal-dialog > .modal-content > .modal-header > h5 {
    word-break: break-word;
}

.modal-body {
    word-break: break-word;
}

.modal-confirmation > .modal-dialog > .modal-content > .modal-header > .modal-header-icon {
    margin-bottom: 20px;
    font-size: 80px;
    color: #f15e5e;
}

.modal-confirmation > .modal-dialog > .modal-content > .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    padding: 10px 15px 25px;
}

.modal-confirmation > .modal-dialog > .modal-content > .modal-footer > button {
    border: none;
    min-height: 40px;
    min-width: 120px;
    border-radius: 25px !important;
}

.modal-footer {
    justify-content: space-evenly !important;
}

.modal-ticket > .modal-dialog {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    max-width: 600px !important;
}

.form-group {
    width: 30%;
    transition: all 0.2s ease-in-out;
}

.checkout-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    /*padding: 0 32px;*/
    width: 100%;
    max-width: 1044px;
    margin: 25px auto;
}

.checkout-route-container {
    padding-right: 5px;
    padding-left: 5px;
}

.checkout-route, .checkout-timeout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 20px;
    background: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
    margin-bottom: 16px;
}

.checkout-route > div, .checkout-timeout > div {
    width: 100%;
}

.checkout-route > div:first-child {
    padding-bottom: 16px;
    margin-bottom: 20px;
    font-size: 26px;
    border-bottom: 1px solid #ddd;
}

.checkout-route > div:last-child {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgb(221, 221, 221);
    font-size: 14px;
    color: #47AB43;
}

.checkout-timeout {
    flex-direction: row;
    justify-content: space-between;
}

.checkout-timeout > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 50%;
}

.timer-item {
    font-size: 20px;
    line-height: 26px;

    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0px 8px;
    background-color: rgb(246, 245, 245);
    border-radius: 8px;
}

.checkout-container > div:first-child {
    min-width: 66.666667%;
}

.checkout-container > div:last-child {
    min-width: 275px;
    max-width: 338px;
}

.checkout-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ticket-price {
    font-size: 16px;
    margin-top: 16px;
    padding-bottom: 24px;
}

.full-price {
    font-size: 20px;
}

.checkout-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 5px;
}

.checkout-form > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 16px;
    padding: 32px;
    background-color: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
    width: 100%;
}

.checkout-form > div:last-child {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.checkout-form .form-group {
    /*margin-bottom: 0 !important;*/
    width: 50%;
    padding-right: 5px;
    padding-left: 5px;
}

.checkout-form input {
    height: 46px;
}

.checkout-form-name > div:first-child, .checkout-form-email > div:first-child, .checkout-form-price > div:first-child {
    margin-bottom: 15px;
    font-size: 26px;
    line-height: 1.36364em;
}

.checkout-form-name > div:last-child, .checkout-form-email > .custom-form-group, .checkout-form-price > div:last-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.pseudo-input {
    display: flex;
    flex-direction: column;
    /*width: 50%;*/
    padding-right: 5px;
    padding-left: 5px;
}

.pseudo-input button {
    width: 100%;
    height: 48px;
    padding: 0;
    color: #272424;
    border: 1px solid #272424;
    border-radius: 6px;
    font-size: 14px;
}

.checkout-form-email-info {
    position: relative;
    padding-left: 32px;
    margin: 5px 0 24px -32px;

    font-size: 14px;
    line-height: 1.42857;
    color: #424543;
}

.checkout-form-email-info::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: #ff8400;
}

.checkout-form-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.checkout-form-btn button {
    width: 100% !important;
    margin: 0;
}

.checkout-form-security {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 9px;
    white-space: pre-wrap;
    text-align: center;
    font-size: 12px;
    color: #a7a7a7;
}

.checkout-form-price-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.checkout-form-price-info > div:first-child {
    font-size: 14px;
    line-height: 1.42857;
    color: #424543;
    flex-basis: 80%;
}

.checkout-form-price-info > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: 20%;
    font-size: 32px;
    color: #424543;
}

.checkout-form-price-info > div:last-child > i {
    padding: 2px;
}

.checkout-form-price-info2 .payment-price {
    margin-top: 0px;
    margin-bottom: 6px;
    font-size: 20px;
    line-height: 26px;
    color: rgb(39, 36, 36);
    font-weight: 400;
}

.checkout-form-price-info2 .payment-info {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    color: rgb(83, 83, 83);
}

.checkout-form-price-info2 .payment-privacy {
    font-size: 12px;
    line-height: 16px;
    color: rgb(83, 83, 83);
}

.checkout-form-price > div:last-child {
    flex-direction: column;
}

.checkout-form-price > div:last-child > label {
    align-items: flex-start;
}

.checkout-form-price > div:last-child > label:last-child {
    margin-bottom: 0;
}

.interactive-link {
    cursor: pointer;
    align-self: center;
    border-bottom: 1px dashed #318f2e;
    color: #47AB43;
    transition: all 0.2s ease-in-out;
}

.interactive-link:hover {
    color: #318f2e;
}

.checkout-form-price > div:last-child > label {
    margin-left: 0;
}

.checkout-form-price > div:last-child > label > span:first-child {
    padding: 0;
    margin-right: 9px;
}

.checkout-promo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.checkout-promo > div:first-child {
    width: 50%;
}

.checkout-promo button {
    margin-left: 5px;
}

.ticket-price > span:last-child .fraction {
    font-size: 12px;
}

.full-price > span:last-child .fraction {
    font-size: 14px;
}

.checkout-form-price > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 13px;
}

.green-focus input:focus {
    border-color: #51bb4c;
    outline: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125), 0 0 8px rgba(71, 171, 67, 0.5);
}

.checkout-form .control-label {
    color: #758189;
}

.checkout-form .dropdown > div > div:first-child, .checkout-form .dropdown > div > div:nth-child(2) {
    height: 46px !important;
}

.checkout-form .dropdown input {
    height: 100%;
}

.css-1sdh7g9-control:hover, .css-1sdh7g9-control:focus, .css-1sdh7g9-control:focus-within {
    border-color: #51bb4c !important;
    outline: none !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125), 0 0 8px rgba(71, 171, 67, 0.5) !important;
}

.modal-map > .modal-dialog {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: fit-content !important;
}

.modal-code-body {
    display: flex;
    flex-direction: column;
}

.modal-code-header {
    flex-direction: row !important;
    justify-content: space-between !important;
}

.modal-fa-times {
    /*background-color: #47AB43;*/
    padding: 4px 7px;
    margin: 0;
    font-size: 22px;
    cursor: pointer;
    color: #f15e5e;
}

.modal-code-title {
    font-size: 16px;
    font-weight: 500;
}

.modal-code-body {
    font-size: 16px;
    line-height: 20px;
}

.modal-code-input {
    min-width: 175px;
    width: 175px;
    height: 56px;
    padding: 10px 25px;
    font-size: 27px;
    letter-spacing: 19px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    outline: none;
}

.margin-vertical {
    margin-top: 15px;
    margin-bottom: 50px;
}

.margin-bottom {
    margin-bottom: 15px;
}

.margin-top {
    margin-top: 15px;
}

.valid, .date-valid input {
    border-color: #c4c4c4;
}

.invalid, .date-invalid input {
    border-color: #ff4d4f;
}

.bus-seat-template {
    width: 20px !important;
    min-width: 20px !important;
    max-width: 20px !important;
    height: 20px !important;
}

.bus-free-seat {
    width: 38px;
    height: 38px;
    min-width: 38px;
    max-width: 38px;
    margin: 4px;
    border: 1px solid #47ab43;
    background-color: transparent;
    padding: 0;
    border-radius: 5px;
}

.bus-taken-seat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    min-width: 38px;
    max-width: 38px;
    height: 38px;
    margin: 4px;
    background-color: #dedede;
    border: 1px solid #dedede;
    border-radius: 5px;
    color: #9a9a9a;
    padding: 0;

}

.bus-empty-seat {
    width: 38px;
    min-width: 38px;
    max-width: 38px;
    height: 38px;
    margin: 4px;
}

.bus-selected-seat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    min-width: 38px;
    max-width: 38px;
    height: 38px;
    margin: 4px;
    background-color: #47ab43;
    border: 1px solid #47ab43;
    border-radius: 5px;
    color: white;
    padding: 0;

}

.bus-map-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bus-map-info > div:last-child {
    margin-left: 15px;
}

.bus-map {
    display: flex;
    flex-direction: row;
    border: 1px solid #c4c4c4;
    border-radius: 30px;
    padding: 20px;
    margin: 12px 0;
}

.bus-seat-line {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*margin: 5px;*/
}

.modal-map > .modal-dialog {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: fit-content !important;
}

.pre-wrap {
    white-space: pre-wrap;
}

.btn-seat-picker {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 20px !important;
    color: #272424;
    border: 1px solid #272424;
    border-radius: 6px;
    font-size: 14px;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    min-width: 270px;
    transition: all 0.2s ease-in-out;
}

.seat-text {
    font-size: 12px !important;
}

.red-elem {
    color: red !important;
    border-color: red !important;
}

.modal-info > .modal-dialog {
    max-width: 760px !important;
}

.modal-info-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.wide-btn {
    width: 195px;
    margin-top: 10px !important;
}

.green-btn {
    background-color: #47ab43;
    color: white;

    transition: all 0.2s ease-in-out;
}

.green-btn:hover {
    background-color: #51bb4c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.0125), 0 0 8px rgba(71, 171, 67, 0.5) !important;
    color: white;
}

.pnf-container {
    height: auto;
    min-height: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 25px;
    margin: 15px 0;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(150, 150, 150, 1);
    -moz-box-shadow: 0px 0px 15px -10px rgba(150, 150, 150, 1);
    box-shadow: 0px 0px 15px -10px rgba(150, 150, 150, 1);

    /*workaround*/
    align-self: center;
}

.pnf {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.pnf-icon {
    max-width: 350px;
    max-height: 260px;
}

.payment-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 50px;
}

.fraction {
    font-size: 14px;
}

.body-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 25px;
    flex: 1;
}

.footer {
    background-color: #f5f5f5;
    /*border-top: 1px solid #ddd;*/
    padding: 10px 0 25px;
    box-shadow: 0px 0px 15px -10px rgba(150, 150, 150, 1);

}

.footer > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /*padding: 0 32px;*/
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
    height: 100%;
}

.footer > .container > .icons img {
    height: 64px;
}

.footer > .container > .icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    border-bottom: 1px solid rgb(233, 233, 233);
}

.footer > .container > .links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
    padding: 25px 0;
    border-bottom: 1px solid rgb(233, 233, 233);

    font-size: 14px;
    line-height: 18px;
    word-break: break-all;
}

.footer > .container > .links > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 10px;
}

.footer > .container > .links > div:last-of-type {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0;
}

.footer > .container > .links > div > a {
    margin-bottom: 5px;
    color: #424543 !important;
    transition: all 0.2s ease-in-out;
}

.footer > .container > .links > div:last-of-type > a:first-of-type {
    margin-right: 10px;
}

.footer > .container > .links img {
    height: 40px;
}

.copyright {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    color: #a7a7a7;
    font-size: 12px;
    margin-top: 25px;
}

.content-block, .site-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 940px;
    padding: 0 8px;
    margin: 25px auto;
}

.content-block p, .content-block span, .content-block a {
    background-color: transparent !important;
}

.content-block a {
    text-decoration: none;
}

.content-block > div:first-of-type {
    font-weight: 500;
    font-size: 34px;
    margin-bottom: 15px;
    text-transform: uppercase;
    word-break: break-word;
}

.content-block > div:last-of-type {
    font-size: 14px;
    line-height: 1.42857;
    background-color: transparent;
    word-break: break-word;
}

.site-login {
    align-items: center;
}

.login-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
    /*background-color: #fff;*/
    margin-top: 25px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.28) 1px 8px 15px;
    min-height: 350px;
    height: 100%;

    word-break: break-word;
}

.login-form > div {
    height: 400px;
    flex-basis: 50%;

    padding: 20px 50px;
    min-height: 350px;
}

.login-form > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: linear-gradient(0.9turn, #3eb98b, #47AB43);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /*border-right: 2px dashed #fff;*/
    color: white;
}

.login-form > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.login-form > div:last-of-type > div {
    width: 100%;
}

.login-form > div:last-of-type > div:first-of-type {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
}

.login-form > div:last-of-type > .form-group {
    max-width: 300px;
    color: rgb(180, 171, 171) !important;
}

.login-form > div:last-of-type > .form-group input {
    height: 50px;
}

.login-form .PhoneInputCountry {
    display: none;
}

.login-form .header-logo {
    justify-content: flex-start;
}

.logo-icon-alt {
    background-color: white;
    color: #47AB43;
}

.login-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    padding: 20px;
    font-size: 13px;
}

.login-placeholder > div:first-of-type {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 10px;
}

.login-btn {
    height: 50px;
    border-radius: 25px;
    width: 100%;
    max-width: 300px;
}

.login-code-title {
    max-width: 300px;
    line-height: 1.2;
    margin-bottom: 10px;
    font-size: 14px;
}

.login-code-title i {
    font-size: 18px;
    margin-left: 5px;
}

.login-code-resend {
    margin-top: 10px;
    text-align: center;
    width: auto;
    max-width: 300px;
    font-size: 14px;
}

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    max-width: 980px;
    padding: 0 32px;
    margin: 25px auto;
    transition: all 0.2s ease-in-out;
}

.profile-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.profile-info > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    height: 180px;
    width: 48%;

    padding: 20px 50px;
    min-height: 50px;
    max-height: 180px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 1px 5px 5px;
}

.profile-info > div .form-group {
    width: 100%;
}

.profile-info .form-group {
    margin-top: 10px;
}

.profile-info > div .form-group input {
    background-color: #fbfbfb;
}

.profile-info > div .form-group label {
    color: #a7a7a7;
}

.profile-info > div .form-group .invalid-feedback, .profile-info > div .form-group .valid-feedback {
    display: none !important;
}

.profile-info > div .form-group input:disabled {
    background-color: transparent;
    color: #999;
}

.profile-email {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
}

.edit {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    font-size: 25px;
}

.edit > span {
    margin-left: 5px;
}

.profile-trips {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    margin-top: 35px;
    border-radius: 10px;
}

.profile-trips-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-basis: 20%;
    font-size: 18px;
    border-left: 5px solid #e5e5e5;
    padding: 25px 0;
}

.profile-trips-menu > div {
    margin-bottom: 25px;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.12s ease-in-out;
}

.profile-trips-menu > div:last-of-type {
    margin-bottom: 0;
}

.profile-trips-menu > div:hover {
    color: #47AB43;
    border-left: 5px solid #47AB43;
    margin-left: -5px;
}

.active-profile-link {
    color: #47AB43;
    border-left: 5px solid #47AB43;
    margin-left: -5px;
}

.profile-trips-container {
    flex-basis: 80%;
    border-radius: 5px;
    /*background-color: #fff;*/
    /*box-shadow: rgba(0, 0, 0, 0.25) 1px 5px 5px;*/
    min-height: 400px;
}

.full-width {
    width: 100%;
}

.profile-trip-pay-status {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
}

.profile-trip-pay-status-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.download-ticket {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

}

.download-ticket > .pdf-icon span {
    font-size: 16px !important;
    text-decoration: underline;
}

.order-status {
    cursor: default;
    display: inline;
    white-space: nowrap;
    border-radius: 3px;
    padding: 3px 7px;
    font-size: 12px;
    width: auto;
}

.status-success {
    background-color: rgba(122, 183, 43, 0.1);
    border: 1px solid rgba(122, 183, 43, 0.4);
    color: rgb(122, 183, 43);
}

.status-warning {
    background-color: rgba(255, 149, 0, 0.1);
    border: 1px solid rgba(255, 149, 0, 0.4);
    color: rgb(255, 149, 0);
}

.status-error {
    background-color: rgba(183, 43, 43, 0.1);
    border: 1px solid rgba(183, 43, 43, 0.4);
    color: rgb(183, 43, 43);
}

.status-info {
    background-color: rgba(43, 134, 183, 0.1);
    border: 1px solid rgba(43, 134, 183, 0.4);
    color: rgb(43, 134, 183);
}

.profile-detailed-info {
    display: flex;
    flex-direction: column;

}

.profile-detailed-info > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    flex-wrap: wrap;
    margin-bottom: 10px;
}

.profile-detailed-info > div:first-of-type > div:first-of-type {
    margin-right: 15px;
}

.profile-detailed-info > div:first-of-type > div > span:first-of-type,
.profile-detailed-info > div:last-of-type > div > span:first-of-type,
.profile-detailed-info .profile-passengers > div > span:first-of-type {
    text-align: left;
    font-size: 14px;
    line-height: 1.12857;
    color: #424543;
    font-weight: 500;
}

.profile-detailed-info > div:first-of-type > div > span:last-of-type {
    font-size: 12px;
    font-weight: 400;
}

.profile-detailed-info > div:first-of-type > div:last-of-type {
    font-size: 14px;
}

.profile-detailed-info .profile-passengers > div > span:first-of-type {
    margin-left: 5px;
}

.profile-detailed-info > div:last-of-type > div {
    margin-bottom: 5px;

}

.profile-detailed-info > div:last-of-type > div > span:first-of-type {
    color: #47AB43;
    font-weight: 400 !important;
    text-align: left;
    line-height: 1.29;
    font-size: 13px;
}

.profile-passengers .passengers-holder {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 25px;
}

.profile-passengers > div:last-of-type {

}

.profile-trip-passenger {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    /*line-height: 1.4;*/
}

.pdf-icon {
    color: #47AB43;
    cursor: pointer;
    margin-left: 8px;
    font-size: 18px;
}

.profile-trip-item-detailed-left {
    padding: 18px 15px;
}

.profile-trip-item-detailed-left > div:first-of-type {
    padding-top: 10px;
    border-top: 1px solid #d7d7d7;
}

.h-border {
    border-top: none;
}

.warning-trips-placeholder, .data-fetch-fail {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 940px;
    margin: 25px auto;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 24px;

    padding: 25px;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(117, 117, 117, 0.25) 1px 5px 5px;
}

.warning-trips-placeholder > div, .data-fetch-fail > div {
    margin-bottom: 10px;
}

.tickets-bundle {
    position: relative;
    /*color: #47ab43;*/
    color: rgba(71, 171, 67, 0.7);
    font-size: 64px !important;
    width: 72px;
    height: 72px;
    margin-bottom: 25px !important;
}

.tickets-bundle i {
    position: absolute;
    transform: rotateZ(15deg);
    z-index: 2;
    top: 25px;
    bottom: 0;
    left: 0;
    right: 0;
}

.tickets-bundle > i:last-of-type {
    transform: rotateZ(55deg);
    z-index: 1;
    top: 0;
    color: rgba(71, 171, 67, 0.5);
}

.rounded-block {
    border-radius: 25px;
}

.data-fetch-fail {
    font-size: 16px;
}

.auto-width-btn {
    width: auto;
    margin-top: 10px !important;
    padding: 10px !important;
}

.icon-warning {
    color: #ff8400;
    font-size: 64px;
}

.benefits {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;

    width: 100%;
    max-width: 940px;
    padding: 0 8px;
    margin: 25px auto;
}

.benefits-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 265px;
    height: 180px;
    min-height: 145px;
    max-height: 180px;
    padding: 15px;
    margin-top: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(181, 181, 181, 0.25) 1px 5px 8px;
}

.benefits-item > div {
    margin-top: 10px;
}

.benefits-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 56px;
    color: #47AB43;
}

.benefits-title {
    font-size: 18px;
    font-weight: 500;
}

.benefits-desc {
    font-size: 14px;
}

.about-block > div {
    margin-bottom: 35px;
}

.about-block > div > div:first-of-type {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 5px;
}

.about-block > div > div:last-of-type {
    /*font-weight: 300;*/
    font-size: 16px;
    line-height: 1.1;
    white-space: pre-wrap;
}

.accordion-title {
    /*max-height: 48px !important;*/
    min-height: 48px !important;
}

.contacts-holder {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 25px;
}

.contacts-holder > div {
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(117, 117, 117, 0.25) 1px 5px 5px;
    padding: 25px;
}

.contacts-holder > div:first-of-type {
    flex-basis: 65%;
}

.contacts-holder > div:last-of-type {
    font-size: 14px;
    margin-left: 15px;
    flex-basis: 35%;
}

.contacts-holder > div:first-of-type > div:first-of-type {
    font-size: 14px;
    white-space: pre-wrap;
}

.contacts-holder > div:last-of-type > span:first-of-type {
    font-size: 16px;
    margin-bottom: 5px;
}

.contacts-holder > div:last-of-type a {
    color: #47AB43;
}

label.required:after {
    content: " *";
    color: red;
}

.contact-form {
    width: 100%;
    margin-top: 20px;
    border-top: 1px solid #d7d7d7;
    padding-top: 20px;
}

.contact-form .form-group {
    width: 100%;
}

.contact-form .form-group label {
    font-size: 14px;
}

.contact-form .form-control {
    box-shadow: none;
}

.mobile-lang {
    box-shadow: none !important;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0 !important;
}

.lang-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 0 !important;
}

.lang-list > p {
    cursor: pointer;
    padding: 5px 32px;
    width: 100%;
}

.lang-list > p > span {
    margin-left: 25px;
}

.lang-list > p:hover {
    background-color: rgb(246, 245, 245) !important;
}

.active-lang {
    display: flex;
    flex-direction: row;
    font-weight: 700;
}

.active-lang::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    margin-right: 10px;
    color: #47ab43;
    position: absolute;
    left: 30px;
    font-size: 16px;
}

.mobile-menu-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #d7d7d7;

    padding: 12px 16px;
}

.mobile-menu-item > a {
    width: 100%;
    padding: 8px 0;
    font-size: 18px;
    color: #272424;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.mobile-menu-item > a:hover {
    color: #47AB43;
}

.mobile-menu-item .menu-active {
    font-weight: 500;
    color: #47AB43;
}

.disable-scroll {
    position: absolute;
    overflow: hidden;
}

.burger {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    cursor: pointer;
    z-index: 10000;
    position: relative;
    width: 40px;
    height: 40px;

    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity, filter;
    text-transform: none;
}

.burger.is-active .burger-inner {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: rotate(45deg);
    background-color: #47AB43;
}

.burger.is-active .burger-inner::before {
    top: 0;
    transition: top 75ms ease, opacity 75ms ease .12s;
    opacity: 0;
    background-color: #47AB43;
}

.burger.is-active .burger-inner::after {
    bottom: 0;
    transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
    transform: rotate(-90deg);
    background-color: #47AB43;
}

.burger-inner, .burger-inner::before, .burger-inner::after {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19);
    transition-duration: 75ms;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;
    content: "";
}

.black-on-white .burger-inner, .black-on-white .burger-inner::before, .black-on-white .burger-inner::after {
    background-color: #272424;
}

.burger-inner::before {
    top: 10px;
    transition: top 75ms ease .12s, opacity 75ms ease;
}

.burger-inner::after {
    bottom: 10px;
    transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.eticket {
    border: 1px solid #47AB43;
}

.icon-green {
    color: #47AB43;
    font-size: 18px;
    margin-right: 5px;
}

.font-14 {
    font-size: 14px !important;
}

.trip-search-mobile {
    display: none;
}

.docs, .docs p, .docs span, .docs a {
    font-family: 'Gotham Pro' !important;
    font-size: 12px !important;
    color: black !important;
}

.docs a {
    text-decoration: none;
    color: #47AB43 !important;
}

.docs a span, .docs a span ins {
    font-family: 'Gotham Pro' !important;
    font-size: 12px !important;
    text-decoration: none !important;
    color: #47AB43 !important;
}

.route-stops-btn {
    display: flex;
    align-items: center;
    margin-left: 87px;
    color: #47AB43;
    font-size: 14px;
    padding: 2px 3px;
    width: auto;
    cursor: pointer;
}

.route-stops-btn > i {
    margin-right: 5px;
}

.main-data-loader {
    height: 100vh;
}

@keyframes container-expand-animation-y {
    0% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes container-expand-animation-x {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

@media only screen and (max-width: 1160px) {
    .model-header {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1100px) {
    .label-p {
        font-size: 12px;
    }

    .checkout-form-name > div:last-child, .checkout-form-email > .custom-form-group, .checkout-form-price > div:last-child {
        flex-direction: column;
    }

    .checkout-form .form-group, .checkout-promo > div:first-child {
        width: 100%;
    }
}

@media only screen and (max-width: 1027px) {
    .index-page-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .view-actions {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 980px) {
    .ticket-buy {
        flex-direction: column;
    }
}

@media only screen and (max-width: 850px) {
    .bus-map {
        flex-direction: column-reverse;
    }

    .bus-seat-line {
        flex-direction: row;
    }
}

@media only screen and (max-width: 780px) {
    .trips-header {
        padding-bottom: 40px;
        padding-top: 100px;
    }

    .header-items {
        display: none;
    }

    .burger {
        display: flex;
    }

    .ticket-buy, .ticket-full-info {
        display: none !important;
    }

    .ticket-short-info {
        grid-template-columns: 50% 50%;
    }

    .ticket-to {
        border: none;
    }

    .sort-btn {
        font-size: 10px;
    }

    .trips-search-container {
        flex-direction: column;
    }

    .is-mobile-only {
        display: flex !important;
    }

    .is-desktop-only {
        display: none !important;
    }

    .trips-search-container {
        width: 100%;
        /*-webkit-animation: container-expand-animation-y 0.2s ease-in-out;*/
        /*-moz-animation: container-expand-animation-y 0.2s ease-in-out;*/
        /*-o-animation: container-expand-animation-y 0.2s ease-in-out;*/
        /*animation: container-expand-animation-y 0.2s ease-in-out;*/
    }


    .trip-search-directions {
        flex-direction: column;
        width: 95%;
        margin: 10px;
    }

    .trip-search-btn {
        margin-left: 0;
    }

    .trips-title {
        margin-bottom: 10px;
        font-size: 28px;
    }

    .input-placeholder {
        color: #fff !important;
        max-width: calc(100% - 40px) !important;
    }

    .trip-date {
        border-bottom-left-radius: 5px !important;
    }

    .form-custom-input > div {
        max-width: none;
        width: 100%;
    }

    .btn-swap {
        left: 5%;
        right: unset;
        top: 85%;
        background-color: transparent;
        color: #fff;
        font-size: 24px;
    }

    .btn-swap.invert {
        transform: rotate(90deg);
    }

    .btn-swap.normal {
        transform: rotate(-90deg);
    }

    .trip-search-directions {
        background-color: rgba(34, 35, 34, 0.6);
        border-radius: 10px;
    }

    .trip-search-directions > div:first-child .custom-dropdown__control {
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom: 1px solid #fff !important;
    }

    .trip-search-directions .custom-dropdown__control {
        border: none !important;
        background-color: transparent;
        width: 75%;
        height: 75px;
        /*border-right: none !important;*/
    }

    .trip-search-directions .custom-dropdown__input,
    .trip-search-directions .custom-dropdown__single-value,
    .trip-search-directions .custom-dropdown__single-value .dropdown-name {
        color: white;
        font-size: 24px;
    }

    .trip-search-directions .custom-dropdown__menu {
        width: 95%;
        left: 2.5%;
    }

    .custom-dropdown__menu {
        min-width: 140px !important;
    }

    .trip-search-date {
        background-color: white;
        border-radius: 10px !important;
        flex-direction: column;
        margin-top: 15px;
    }

    .trip-search-date .input-placeholder {
        color: #272424 !important;
    }

    .trip-search-date > .form-custom-input > .date-input,
    .trip-search-date > .form-custom-input > .date-input input,
    .trip-search-date > .form-custom-input > .input-type-number {
        background-color: transparent !important;
        border: none;
    }

    .trip-search-date .date-input input {
        border-bottom-left-radius: 5px !important;
    }

    .input-type-number {
        border-top-right-radius: 0;
    }

    .input-type-number > button {
        font-size: 16px;
    }

    .trip-search-date > .form-custom-input {
        min-width: unset;
    }

    .trips-search-container > .form-custom-input:last-of-type {
        width: 100%;
    }

    .trips-search-container > .form-custom-input:last-of-type > .tickets-search-btn {
        margin-left: 0;
        margin-top: 5px;
        width: 100%;
        max-width: none;
    }

    .no-trips {
        align-items: center;
        flex-direction: column;
    }

    .no-trips > div:last-child > div:first-child {
        font-size: 22px;
    }

    .no-trips > div:last-child > div:last-child {
        font-size: 14px;
    }

    .trip-placeholder {
        width: 100%;
    }

    .btn-seat-picker {
        min-width: 140px;
    }

    .PhoneInputCountry {
        display: none !important;
    }

    .checkout-container {
        flex-direction: column-reverse;
    }

    .checkout-container > div:last-child {
        width: 100%;
        max-width: none;
    }

    .header {
        padding: 0 8px;
    }

    .trip-item-short {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .trip-item-short > div {
        width: 100%;
    }

    .trip-buy-holder {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
    }

    .trip-details-btns {
        display: none;
    }

    .profile-trip-details-btns {
        display: flex !important;
    }

    .profile-trip-details-btns > div {
        display: none;
    }

    .trip-carrier-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 0;
    }

    .trip-buy-btn {
        margin-bottom: 0;
    }

    .trip-item-short-right {
        border-left: none;
        border-top: 3px dotted #d7d7d7;
        border-bottom-left-radius: 10px;
    }

    .profile-trip-item-short-right {
        border-top: 1px solid #d7d7d7;
        border-radius: 0;
        background-color: inherit;
    }

    .trip-item-cutout {
        display: block !important;
        width: 15px;
        height: 30px;
        top: -16px !important;
    }

    .trip-item-cutout:first-of-type {
        left: -8px !important;
    }

    .trip-item-cutout:last-of-type {
        right: -8px;
        left: unset !important;
    }

    .trip-item-detailed {
        display: none !important;
    }

    .profile-trip-item-detailed {
        display: flex;
        border-top: 1px solid #d7d7d7;
        grid-template-columns: none;
    }

    .profile-trip-item-detailed .trip-item-detailed-left {
        width: 100%;
    }

    .profile-trip-item-detailed .trip-item-detailed-left div {
        padding: 0;
        border-top: none;
    }

    .login-form {
        flex-direction: column;
    }

    .login-form > div:first-of-type {
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
    }

    .login-form > div:last-of-type {
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
    }

    .login-form > div {
        min-height: 250px;
    }

    .profile-container {
        padding: 0 8px;
    }

    .profile-info {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .profile-info > div {
        width: 100%;
        margin-bottom: 20px;
    }

    .profile-trips {
        flex-direction: column;
    }

    .profile-trips > div {
        flex-basis: unset;
        width: 100%;
    }

    .profile-trips-menu {
        flex-direction: row;
        border-top: 5px solid #e5e5e5;
        border-left: none;
        padding: 0;
        justify-content: space-around;
    }

    .profile-trips-menu > div {
        margin-bottom: 5px;
        font-size: 14px;
        text-align: center;
    }

    .profile-trips-menu > div:hover {
        border-left: none;
        border-top: 5px solid #47AB43;
        margin-left: 0;
        margin-top: -5px;
    }

    .active-profile-link {
        border-left: none;
        border-top: 5px solid #47AB43;
        margin-left: 0;
        margin-top: -5px;
    }

    .none {
        display: none !important;
    }

    .profile-trip-item-detailed-left > div:first-of-type {
        border-top: none;
    }

    .h-border {
        border-top: 1px solid #d7d7d7;
    }

    .contacts-holder {
        flex-direction: column-reverse;
    }

    .contacts-holder > div {
        flex-basis: unset !important;
        width: 100%;
    }

    .contacts-holder > div:last-of-type {
        margin-left: 0;
        margin-bottom: 15px;
    }

    .trips-search-container > .trip-search-date,
    .trips-search-container > .trip-search-btn {
        display: none !important;
    }

    .trip-search-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #eff0f5;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        margin-top: 25px;
        margin-bottom: -10px;
        border-bottom: 1px solid #bebebe;
        padding: 10px 10px 15px;
    }

    .trip-search-mobile > div {
        width: 100%;
        margin: 10px;
        background-color: white;
        box-shadow: 1px 5px 8px hsla(0, 0%, 71%, .25);
        height: 50px;
        border-radius: 5px;
        justify-content: space-between;
    }

    .trip-search-mobile > div > div:last-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        background-color: transparent !important;
        border: none !important;
    }

    .trip-search-mobile .date-input input,
    .trip-search-mobile .input-type-number div:first-of-type {
        background-color: transparent !important;
        border: none !important;
        padding: 0 10px;
        min-height: 40px;
        text-align: right;
        font-weight: 500;
    }

    .trip-search-mobile .date-input .rdtPicker {
        right: 0;
    }

    .trip-search-mobile .input-type-number {
        padding: 0;
        justify-content: flex-end;
        width: 180px;
    }

    .trip-search-mobile .input-type-number input {
        font-weight: 500;
    }

    .trips-header {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
    }

    .mobile-input-placeholder {
        padding-left: 10px;
        text-align: left;
        font-size: 14px;
    }

    .mobile-input-placeholder i {
        font-size: 18px;
        margin-right: 10px;
        color: #6c6c6c;
    }

    .trip-search-mobile .trip-search-btn {
        background: none !important;
        box-shadow: none !important;
    }

    .trip-search-mobile .trip-search-btn .tickets-search-btn {
        width: 100%;
        max-width: unset;
        min-height: 40px;
        height: 100%;
    }

    .footer > .container > .icons {
        justify-content: center;
    }

    .footer > .container > .icons img {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 500px) {
    .custom-input {
        width: 100% !important;
    }

    .tickets-sort-container {
        justify-content: center;
    }

    .trip-time-in-road {
        display: none;
    }

    .trip-buy-btn {
        width: auto;
    }

    .trips-short-info > div:first-of-type > div:first-of-type > div > i {
        font-size: 18px;
    }

    .preorder-header {
        flex-direction: column;
        align-items: flex-end;
    }

    .footer > .container > .links {
        font-size: 12px;
        line-height: 12px;
    }

    .trips-title-routes {
        flex-direction: column;
        align-items: flex-start;
    }

    .trips-title-routes > span:last-of-type {
        margin-top: 5px;
    }
}

@media only screen and (max-width: 360px) {
    .trips-short-info > div > div:first-of-type > div:first-of-type > span:first-of-type {
        font-size: 20px;
    }

    .trips-title-routes {
        font-size: 24px;
    }

    .trips-title-routes > span:last-of-type {
        font-size: 14px;
    }
}

@media only screen and (min-width: 780px) {
    .disable-scroll {
        position: unset;
        overflow: auto;
    }

    .mobile-header {
        display: none !important;
    }
}
