.popular-trips {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 16px 0;

    border-top: 1px solid rgba(150, 150, 150, 0.2);
}

.popular-trips > div:first-of-type {
    font-size: 38px;
    margin-bottom: 20px;
}

.pt-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    max-width: 930px;
    min-height: 20px;
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    /*padding: 10px 25px;*/
    /*margin: 15px auto;*/
}

.pt-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 380px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
    margin: 12px 8px;
    align-self: stretch;
    box-shadow: 0 0 15px -10px #969696;
}

.pt-item-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    margin-bottom: 8px;
}

.pt-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;

    line-height: 30px;
    font-size: 16px;

    color: #47AB43;
    cursor: pointer;

    flex-wrap: wrap;
    word-break: break-word;
}

.pt-item > i {
    margin: 0 5px;
    color: initial;
}

.pt-item:hover {
    text-decoration: underline;
    color: #51bb4c;
}

.back-trip {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    margin: 12px auto 0;
}

.back-trip > span {
    width: auto;
    margin-right: 5px;
    font-size: 16px;
}

.back-trip > a {
    color: #47AB43;
    font-size: 14px;
}

.back-trip > a > i {
    margin: 0 3px;
    color: initial;
}